import React from "react"
import { Link } from "gatsby"

import NavigationSocial from "../navigation-social/navigation-social"
import FormNewsletter from "../form-newsletter/form-newsletter"
import CopyrightSection from "../copyright-section/copyright-section"
import MapImage from "../../images/map-graph.svg"

import {
  footerMain,
  footerMainInner,
  footerForm,
  footerNav,
  footerBackground,
} from "./footer-main.module.scss"

const FooterMain = ({ siteTitle }) => {
  return (
    <footer data-testid="footer-main" className={`${footerMain}`}>
      <div className={footerMainInner}>
        <NavigationSocial />
        <div data-testid="footer-form" className={`${footerForm}`}>
          <h3>Get In Touch</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero
            facilis, voluptatum molestiae quibusdam culpa modi!
          </p>
          <FormNewsletter />
        </div>
        <nav className={footerNav}>
          <div>
            <h2>Services</h2>
            <ul>
              <li>
                <Link to="/service/branding">Branding</Link>
              </li>
              <li>
                <Link to="/service/web-design">Web Design</Link>
              </li>
              <li>
                <Link to="/service/web-development">Web Development</Link>
              </li>
              <li>
                <Link to="/service/website-audits">Website Audits</Link>
              </li>
              <li>
                <Link to="/web-presence">Web Presence</Link>
              </li>
              <li>
                <Link to="/local-marketing">Local Marketing</Link>
              </li>
              <li>
                <Link to="/service/blog-development">Blog Development</Link>
              </li>
              <li>
                <Link to="/lead-generation">Lead Generation</Link>
              </li>
            </ul>
          </div>
          <div>
            <h2>Resources</h2>
            <ul>
              <li>
                <Link to="/services">Services</Link>
              </li>
              <li>
                <Link to="/process">Our Process</Link>
              </li>
              {/* <li>
                <Link to="/portfolio">Portfolio</Link>
              </li> */}
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/articles">Wildfire Blog</Link>
              </li>
            </ul>
          </div>
          <div>
            <h2>Support</h2>
            <ul>
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/disclaimer">Disclaimer</Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <CopyrightSection siteTitle={siteTitle} />
      <div className={footerBackground}>
        <MapImage />
      </div>
    </footer>
  )
}

export default FooterMain
