import { useStaticQuery, graphql } from "gatsby"

const getSiteMetadata = graphql`
  query SiteMetaData {
    site {
      siteMetadata {
        title
        description
        canonicalUrl
        image
        author {
          name
          minibio
        }
        social {
          twitterHandle
          twitter
          github
          linkedin
          facebook
          youtube
          instagram
        }
        organization {
          name
          url
          logo
          phone
          address
        }
      }
    }
  }
`

export const useSiteMetadata = () => {
  const { site } = useStaticQuery(getSiteMetadata)

  return site.siteMetadata
}
