import React from "react"
import { Link } from "gatsby"

import { mainNavigation } from "./navigation-main.module.scss"

const NavigationMain = ({ isScrolled = false }) => {
  return (
    <nav data-testid="main-navigation" className={mainNavigation}>
      <ul>
        <li>
          <Link to="/services">Services</Link>
        </li>
        <li>
          <Link to="/process">Our Process</Link>
        </li>
        <li>
          <Link to="/contact-us">Contact Us</Link>
        </li>
        {isScrolled && (
          <>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/articles">Blog</Link>
            </li>
          </>
        )}
        {/* <li>
          <Link to="/portfolio">Portfolio</Link>
        </li> */}
      </ul>
    </nav>
  )
}

export default NavigationMain
