import React from "react"
import PropTypes from "prop-types"

import { useSiteMetadata } from "../../hooks/use-site-metadata"
// import HeaderMain from "../header-main/header-main"
import FooterMain from "../footer-main/footer-main"

import "./layout-main.scss"

const LayoutMain = ({ children, ...props }) => {
  const { title } = useSiteMetadata()

  return (
    <div className={props.className}>
      {/* <HeaderMain /> */}
      <main className="main-content">{children}</main>
      <FooterMain siteTitle={title} />
    </div>
  )
}

LayoutMain.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default function LayoutWithSiteData(props) {
  const data = useSiteMetadata()
  return <LayoutMain data={data} {...props} />
}
