/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
// import path from 'path'
import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useSiteMetadata } from "../../hooks/use-site-metadata"
import SchemaOrg from "../schema-org/schema-org"

function SeoHeaders({
  siteMetadata: seo,
  lang = "en",
  isBlogPost = false,
  frontmatter: postMeta = {},
  title,
  url,
  image = isBlogPost ? postMeta.banner.childImageSharp.fluid.src : "",
  datePublished = isBlogPost ? postMeta.date : false,
}) {
  const metaDescription = postMeta.description || seo.description
  const metaTitle = postMeta.metaTitle || title

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={metaTitle}
        titleTemplate={`%s | ${seo.title}`}
      >
        <meta name="description" content={metaDescription} />
        <meta name="image" content={image} />

        {/* OpenGraph tags */}
        <meta property="og:url" content={url} />
        {isBlogPost ? <meta property="og:type" content="article" /> : null}
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={image} />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={seo.social.twitter} />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={image} />
      </Helmet>
      <SchemaOrg
        isBlogPost={isBlogPost}
        url={url}
        title={metaTitle}
        image={image}
        description={metaDescription}
        datePublished={datePublished}
        canonicalUrl={seo.canonicalUrl}
        author={seo.author}
        organization={seo.organization}
        defaultTitle={seo.title}
      />
    </>
  )
}

SeoHeaders.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SeoHeaders.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

function SeoHeadersWithQuery(props) {
  const siteMetadata = useSiteMetadata()

  return <SeoHeaders siteMetadata={siteMetadata} {...props} />
}

export default SeoHeadersWithQuery
