import React from "react"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useSiteMetadata } from "../../hooks/use-site-metadata"

import "./navigation-social.scss"

library.add(fab)

const NavigationSocial = () => {
  const { social: { twitter, facebook, youtube, linkedin, instagram } } = useSiteMetadata()

  return (
    <nav className="nav-social-media">
        <ul>
          <li>
            <a
              title="Facebook"
              aria-label="facebook"
              href={facebook}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={["fab", "facebook"]} />
            </a>
          </li>
          <li>
            <a
              title="Twitter"
              aria-label="twitter"
              href={twitter}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={["fab", "twitter"]} />
            </a>
          </li>
          <li>
            <a
              title="YouTube"
              aria-label="youtube"
              href={youtube}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={["fab", "youtube"]} />
            </a>
          </li>
          <li>
            <a
              title="LinkedIn"
              aria-label="linkedin"
              href={linkedin}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={["fab", "linkedin"]} />
            </a>
          </li>
          <li>
            <a
              title="Instagram"
              aria-label="instagram"
              href={instagram}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={["fab", "instagram"]} />
            </a>
          </li>
        </ul>
      </nav>
  )
}

export default NavigationSocial
