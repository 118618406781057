import React from "react"
import { Link } from "gatsby"
import { fullYear } from "../../utils/utils"

import { copyrightSection } from "./copyright-section.module.scss"

const CopyrightSection = ({ siteTitle }) => {
  return (
    <div className={copyrightSection}>
      <div>
        <p>
          &copy; 2019-{fullYear()}, All Rights Reserved by{" "}
          <Link className="no-underline" to="/">
            {siteTitle}
          </Link>
        </p>
      </div>
      <nav>
        <Link to="/privacy-policy">Privacy Policy</Link>
        <Link to="/terms-of-use">Terms of Use</Link>
      </nav>
    </div>
  )
}

export default CopyrightSection
