export const states = [
  "Alabama",
  "Alaska",
  "Arkansas",
  "Arizona",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "Nevada",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
]

export function fullYear() {
  return new Date().getFullYear()
}

export function titleCase(str = "") {
  if (typeof str === "string") {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  } else {
    console.error("please provide a valid string")
  }
}

export function recentPosts(posts, categorySlug = null, num = 3) {
  if (!posts || !Array.isArray(posts) || !posts.length) {
    return []
  } else if (categorySlug) {
    return posts
      .filter(
        ({ node: { childMdx } }) =>
          childMdx.frontmatter.categorySlug === categorySlug
      )
      .slice(0, num)
      .map(normalizePost)
  } else {
    return posts.slice(0, num).map(normalizePost)
  }
}

export function normalizeServices(services, serviceCategory = null) {
  if (!services || !Array.isArray(services) || !services.length) {
    return []
  } else if (serviceCategory) {
    return services
      .filter(
        ({ childMdx }) =>
          childMdx.frontmatter.serviceCategory === serviceCategory
      )
      .map(normalizeService)
  } else {
    return services.map(normalizeService)
  }
}

export function normalizePost({ node: { childMdx, id } }) {
  return {
    slug: childMdx.frontmatter.slug,
    title: childMdx.frontmatter.title,
    banner: childMdx.frontmatter.banner,
    date: childMdx.frontmatter.date,
    id,
    excerpt: childMdx.excerpt,
  }
}

export function normalizeService({ id, childMdx: { frontmatter } }) {
  return {
    slug: frontmatter.slug,
    title: frontmatter.title,
    id,
    excerpt: frontmatter.excerpt,
  }
}
