import React, { useState } from "react"

import { formNewsletter } from "./form-newsletter.module.scss"

const FormNewsletter = () => {
  const [form, updateForm] = useState({
    emailAddress: "",
  })
  const [isSubmitted, updateSubmit] = useState(false)

  const handleSubmit = (evt) => {
    evt.preventDefault()
    updateSubmit(true)
  }

  const handleChange = ({ target }) => {
    updateForm({ emailAddress: target.value })
  }

  return (
    <div className={formNewsletter}>
      {!isSubmitted ? (
        <form data-testid="form-newsletter" onSubmit={handleSubmit}>
          <label htmlFor="emailAddress">Email Address:</label>
          <input
            type="email"
            onChange={handleChange}
            defaultValue={form.emailAddress}
            id="emailAddress"
            name="emailAddress"
            required
          />
          <button type="submit">Subscribe</button>
        </form>
      ) : (
        <p className="form-message">Thank you for signing up!</p>
      )}
    </div>
  )
}

export default FormNewsletter
