import React, { useState } from "react"

import Logo from "../logo-main/logo-main"
import NavigationMain from "../navigation-main/navigation-main"
import NavigationMeta from "../navigation-meta/navigation-meta"
import OffscreenMenu from "../offscreen-menu/offscreen-menu"

import { useScrollHandler } from "../../hooks/use-scroll"
import { useSiteMetadata } from "../../hooks/use-site-metadata"

import {
  headerMain,
  headerInner,
  logoHolder,
  navigationHolder,
  menuDisplayButton,
  active,
} from "./header-main.module.scss"

const HeaderMain = () => {
  const { title } = useSiteMetadata()
  const scroll = useScrollHandler()
  const [navStatus, toggleNav] = useState(false)
  const handleClick = () => {
    toggleNav(!navStatus)
  }

  return (
    <header
      data-testid="header-main"
      className={`${headerMain} ${scroll ? "" : active}`}
    >
      <div className={headerInner}>
        <div className={logoHolder}>
          <Logo siteTitle={title} />
        </div>
        <div className={navigationHolder}>
          <NavigationMeta />
          <NavigationMain isScrolled={!scroll} />
        </div>
        <button className={menuDisplayButton} onClick={handleClick}>
          Menu
        </button>
        <OffscreenMenu isActive={navStatus} handleClose={handleClick} />
        <button
          aria-label="click to close"
          onClick={handleClick}
          className={`blackout ${navStatus ? "active" : ""}`}
        ></button>
      </div>
    </header>
  )
}

export default HeaderMain
