import React from 'react'
import { Link } from "gatsby"

import "./offscreen-menu.scss"

const OffscreenMenu = ({ isActive, handleClose }) => {
  return (
    <nav className={`offscreen-navigation ${isActive ? 'active' : ''}`}>
      <button
        className="menu-display-button"
        onClick={() => { handleClose(false) }}
      >&times;</button>
      <ul className="offscreen-menu">
        <li className="offscreen-menu-item">
          <Link to="/about">About</Link>
        </li>
        <li className="offscreen-menu-item">
          <Link to="/articles">Blog</Link>
        </li>
        <li className="offscreen-menu-item">
          <Link to="/services">Services</Link>
        </li>
        <li className="offscreen-menu-item">
          <Link to="/process">Our Process</Link>
        </li>
        <li className="offscreen-menu-item">
          <Link to="/contact-us">Contact Us</Link>
        </li>
        {/* <li className="offscreen-menu-item">
          <Link to="/portfolio">Portfolio</Link>
        </li> */}
      </ul>
    </nav>
  )
}

export default OffscreenMenu
