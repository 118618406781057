import React from "react"
import { Link } from "gatsby"

import { metaNavigation } from "./navigation-meta.module.scss"

const NavigationMeta = () => {
  return (
    <nav className={metaNavigation}>
      <ul>
        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <Link to="/articles">Blog</Link>
        </li>
      </ul>
    </nav>
  )
}

export default NavigationMeta
