import React from "react"
import { Link } from "gatsby"
import LogoImage from "../../images/logo.png"

import { useSiteMetadata } from "../../hooks/use-site-metadata"
import { logo } from "./logo-main.module.scss"
const Logo = () => {
  const { title: siteTitle } = useSiteMetadata()

  return (
    <div data-testid="logo-main" className={logo}>
      <Link to="/">
        <img src={LogoImage} alt="logo" />
        <span>{siteTitle}</span>
      </Link>
    </div>
  )
}

export default Logo
